@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
/* Import your custom font */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
/* Apply the custom font to elements */
body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1, .title-1 {
  font-size: 40px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.04px;
  text-transform: uppercase;
}

h2, .title-2 {
  font-size: 36px;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 0.036px;
}

h3, .title-3 {
  font-size: 32px;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.032px;
}

h4, .title-4 {
  font-size: 28px;
  font-weight: 400;
    line-height: 34px;
  letter-spacing: 0.028px;
}

.subtitle-1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.024px;
}
.subtitle-1-1 {
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
}

.subtitle-2 {
  font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  letter-spacing: 0.02px;
}

.subtitle-3 {
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.016px;
}

.button-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
}
.button-anchor {
  width: 345px;
  height: 62px;
  position: relative;
}

.button-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}
.paragraph {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
.producer-label {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}
.producer-value {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
.producer-name {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
}

.producer-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}
  
.characteristic-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}
  
.characteristic-description {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}
.website-text {
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
}
.loader-text {
  font-size: 20px;
  line-height: 24px;
  font-weight: 400;
}

.loader-text-mobile {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
.scroll-container {
  scroll-behavior: smooth;
}
p, span {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.016px;
}
button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@layer components {
  .linear-gradient {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.09) 0%, #16140E 210.5%)
  }
  .linear-gradient-sm {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.09) 0%, #16140E 105.5%)
  }
  .linear-gradient-md {
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #16140E 620.5%)
  }
    .linear-gradient-180deg {
      background: linear-gradient(180deg, rgba(22, 20, 14, 0) 0%, #16140E 100%);
      transform: rotate(-180deg);
    }
    .linear-gradient-0deg {
      background: linear-gradient(180deg, rgba(22, 20, 14, 0) 0%, #16140E 100%);
    }
  
    .transform-180 {
      transform: rotate(180deg);
    }

  /* Calculate opened menu width */
  .menu-width {
    width: calc(100% - 113px);
  }

  .hide-scrollbar::-webkit-scrollbar{
    display: none;
  } 
}