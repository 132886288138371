.custom-loader {
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#ffff 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#ffff);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}
